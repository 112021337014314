const productsMoreButton = document.querySelector('#productsMore');
const productsContainer = document.querySelector('#productsContainer');

let loading = false;

if (productsMoreButton && productsContainer) {
    window.productsPage = 1;
    productsMoreButton.addEventListener('click', async e => {
        if (loading) {
            return;
        }
        loading = true;

        try {
            const res = await axios.get(window.location.href, {
                params: {
                    page: window.productsPage + 1,
                },
            });

			const hasMore = res.headers['x-has-more'] === '1';

            if (res.data !== '') {
                const div = document.createElement('div');
                div.innerHTML = res.data;
                productsContainer.append(...div.children);
                window.productsPage++;
            }
			if (!hasMore) {
                productsMoreButton.classList.add('d-none');
            }
        } finally {
            loading = false;
        }
    });
}
