import Validation from './util/validation.js';

const profileUpdateForm = document.getElementById('profileUpdateForm');

if (profileUpdateForm) {
    profileUpdateForm.addEventListener('submit', async e => {
        e.preventDefault();
        e.stopPropagation();

        const data = new FormData(e.target);
        const validation = new Validation(e.target);
        try {
            validation.clearErrors();
            await axios.post('/profile', data);
            showSuccessModalNotification();
        } catch (e) {
            if (e?.response?.status === 422) {
                validation.addErrors(e.response.data.errors);
            }
        }
    });

    profileUpdateForm.querySelector('[name="avatar"]').addEventListener('change', e => {
        const files = e.target.files;

        if (FileReader && files && files.length) {
            const fr = new FileReader();
            fr.onload = function () {
                profileUpdateForm.querySelector('img').src = fr.result;
            }
            fr.readAsDataURL(files[0]);
        }
    });

    function showSuccessModalNotification() {
        const template = document.body.querySelector('#formSuccess');
        if (template) {
            const clone = template.content.cloneNode(true);
            openModal(clone);
            setTimeout(() => {
                window.closeModal();
            }, 1000);
        }
    }
}
