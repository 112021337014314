import Validation from "./util/validation.js";

const userIdTag = document.head.querySelector('meta[name="userId"]');

window.authenticated = !!userIdTag;

window.register = async (formEl) => {
    const data = new FormData(formEl);
    const validation = new Validation(formEl);

    try {
        validation.clearErrors();
        await axios.post('/signup', data);
		return true;
    } catch (e) {
        if (e?.response?.status === 422) {
            validation.addErrors(e.response.data.errors)
        }
		return false;
    }
};

window.sendPasswordResetLink = async (formEl) => {
    const data = new FormData(formEl);
    const validation = new Validation(formEl);

    try {
        validation.clearErrors();
        await axios.post(formEl.getAttribute('action'), data);
        return true;
    } catch (e) {
        if (e?.response?.status === 422) {
            validation.addErrors(e.response.data.errors)
        }
        return false;
    }
}

document.getElementById('resetPasswordForm')?.addEventListener('submit', async e => {
    e.preventDefault();
    e.stopPropagation();

    const data = new FormData(e.target);
    const validation = new Validation(e.target);

    try {
        validation.clearErrors();
        await axios.post(e.target.getAttribute('action'), data);
        window.location.href = '/';
    } catch (e) {
        if (e?.response?.status === 422) {
            validation.addErrors(e.response.data.errors)
        }
    }
});
