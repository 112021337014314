const openLoginModal = function () {
	const template = document.body.querySelector('#formLogin');
	if (template) {
		const clone = template.content.cloneNode(true);
		// addLoginModalButtonsListeners(clone);
		openModal(clone);
	}
}

function openRegisterModal(e) {
	e.preventDefault();
	e.stopPropagation();

	const template = document.body.querySelector('#formRegister');

	if (template) {
		const clone = template.content.cloneNode(true);
		openModal(clone);
	}
}

window.openRegisterModal = openRegisterModal;
window.openForgotPasswordModal = openForgotPasswordModal;

function openForgotPasswordModal(e) {
	e.preventDefault();
	e.stopPropagation();

	const template = document.body.querySelector('#formForgotPassword');

	if (template) {
		const clone = template.content.cloneNode(true);
		const form = clone.querySelector('form');
		const successAlert = clone.querySelector('.success');
		form.addEventListener('submit', async e => {
			e.preventDefault();
			e.stopPropagation();
			const result = await window.sendPasswordResetLink(e.target);
			if (result) {
				form.classList.add('d-none');
				successAlert.classList.remove('d-none');
			}
		});
		openModal(clone);
	}

	return false;
}

function openCallbackModal() {
	const template = document.body.querySelector('#formCallback');
	if (template) {
		const clone = template.content.cloneNode(true);
		const form = clone.querySelector('form');
		const successAlert = clone.querySelector('.success');
		form.addEventListener('submit', async e => {
			e.preventDefault();
			e.stopPropagation();
			const result = await window.sendCallback(e.target);
			if (result) {
				form.classList.add('d-none');
				successAlert.classList.remove('d-none');
			}
		});
		openModal(clone);
	}
}

function openPromocodeModal(e) {
	e.preventDefault();
	e.stopPropagation();
	const template = document.body.querySelector('#formPromocode');
	if (template) {
		const clone = template.content.cloneNode(true);
		openModal(clone);
	}
}

function openLoyaltyModal() {
	const template = document.body.querySelector('#loyalty');
	if (template) {
		const clone = template.content.cloneNode(true);
		openModal(clone);
	}
}

function openPriceListModal(e) {
	const template = document.body.querySelector('#formPriceList');
	if (template) {
		const clone = template.content.cloneNode(true);
		const form = clone.querySelector('form');
		const successAlert = clone.querySelector('.success');
		form.querySelector('#send_price_list')?.addEventListener('click', async e => {
			if (e.target.checked) {
				// form.querySelector('#email').setAttribute('required', true);
				form.querySelector('#emailLabel').innerText = 'E-mail *';
			} else {
				// form.querySelector('#email').removeAttribute('required', true);
				form.querySelector('#emailLabel').innerText = 'E-mail';
			}
		});
		form.addEventListener('submit', async e => {
			e.preventDefault();
			e.stopPropagation();
			const result = await window.sendCallback(e.target);
			if (result) {
				form.classList.add('d-none');
				successAlert.classList.remove('d-none');
			}
		});
		openModal(clone);
	}
}

window.openVideoModal = (e) => {
	const videoUrl = e.target.closest('button').dataset.videoUrl;
	console.log('video', e, videoUrl);
	const modal = document.body.querySelector('#videoModal');
	const elementModalOuter = modal.querySelector('#modalOuter');
	const iframe = modal.querySelector('iframe');
	iframe.setAttribute('src', videoUrl);
	elementModalOuter.addEventListener('click', closeVideoModal);
	modal.querySelector('#buttonModalClose')?.addEventListener('click', closeVideoModal)
	document.body.classList.add('--hidden');
	modal.classList.add('-show');
	setTimeout(() => {
		modal.classList.add('-animate');
	}, 20)
}

window.closeVideoModal = function () {
	const modal = document.body.querySelector('#videoModal');
	const iframe = modal.querySelector('iframe');
	modal.classList.remove('-animate');
	setTimeout(() => {
		modal.classList.remove('-show');
		document.body.classList.remove('--hidden');
		iframe.setAttribute('src', '');
	}, 500)
}

function openModal(clone) {
	const elementModal = document.getElementById('modal');
	if (elementModal) {
		const elementModalOuter = elementModal.querySelector('#modalOuter');
		const modalContent = elementModal.querySelector('#modalContent');
		elementModal.querySelector('#buttonModalClose')?.addEventListener('click', window.closeModal)

		document.body.classList.add('--hidden');
		elementModalOuter.addEventListener('click', window.closeModal);
		modalContent.innerHTML = '';
		modalContent.appendChild(clone);
		elementModal.classList.add('-show');
		setTimeout(() => {
			elementModal.classList.add('-animate');
		}, 20)
	}
}

window.closeModal = function () {
	const elementModal = document.body.querySelector('#modal');
	if (elementModal) {
		elementModal.classList.remove('-animate');
		setTimeout(() => {
			elementModal.classList.remove('-show');
			document.body.classList.remove('--hidden');
		}, 500)
		setTimeout(() => {
			const modalContent = document.body.querySelector('#modalContent');
			modalContent.innerHTML = '';
		}, 800)
	}
}

window.openModal = openModal;

document.querySelectorAll('button[data-modal="callback"]').forEach(btn => {
	btn.addEventListener('click', openCallbackModal);
});
document.querySelectorAll('button[data-modal="login"]').forEach(btn => {
	btn.addEventListener('click', openLoginModal)
});
document.querySelectorAll('button[data-modal="register"]').forEach(btn => {
	btn.addEventListener('click', openRegisterModal)
});
document.querySelectorAll('button[data-modal="promocode"]').forEach(btn => {
	btn.addEventListener('click', openPromocodeModal)
});
document.querySelectorAll('button[data-modal="priceList"]').forEach(btn => {
	btn.addEventListener('click', openPriceListModal)
});
document.querySelectorAll('button[data-modal="loyaltyInfo"]').forEach(btn => {
	btn.addEventListener('click', openLoyaltyModal)
});
// document.querySelectorAll('button[data-modal="video"]').forEach(btn => {
// 	btn.addEventListener('click', openVideoModal)
// });
document.getElementById('closeModal').addEventListener('click', window.closeModal);
window.openLoginModal = openLoginModal;
