import Glide from '@glidejs/glide'

const indexSaleCarousel = document.querySelector('#indexSaleCarousel');

if (indexSaleCarousel) {
    new Glide('#indexSaleCarousel', {
        type: 'slider',
        perView: 4,
        gap: 0,
		bound: true,
        breakpoints: {
            740: {
                perView: 1,
            },
            1040: {
                perView: 2,
            },
            1440: {
                perView: 3,
            },
        },
    }).mount();
}

//----------------------------------------------------------------------------------------------------------
const indexFsCarousel = document.querySelector('#indexFsCarousel');

if (indexFsCarousel) {
	new Glide('#indexFsCarousel', {
		type: 'carousel',
		perView: 1,
		gap: 20,
		autoplay: 4000,
		hoverpause: true,
		animationDuration: 1500,
		rewindDuration: 1000,
		rewind: true,
	}).mount();
}

//----------------------------------------------------------------------------------------------------------
const indexCertificatesCarousel = document.querySelector('#indexCertificatesCarousel');

if (indexCertificatesCarousel) {
    new Glide('#indexCertificatesCarousel', {
        type: 'slider',
		bound: true,
        perView: 6,
        breakpoints: {
            500: {
                perView: 1,
            },
            767: {
                perView: 2,
            },
            900: {
                perView: 3,
            },
            1080: {
                perView: 4,
            },
            1440: {
                perView: 5,
            },
        },
    }).mount();
}

//----------------------------------------------------------------------------------------------------------
const indexContractsCarousel = document.querySelector('#indexContractsCarousel');

if (indexContractsCarousel) {
    new Glide('#indexContractsCarousel', {
        type: 'slides',
        perView: 6,
		bound: true,
        breakpoints: {
            500: {
                perView: 1,
            },
            767: {
                perView: 2,
            },
            900: {
                perView: 3,
            },
            1080: {
                perView: 4,
            },
            1440: {
                perView: 5,
            },
        },
    }).mount();
}

//----------------------------------------------------------------------------------------------------------
const indexFeedbackCarousel = document.querySelector('#indexFeedbackCarousel');

if (indexFeedbackCarousel) {
    new Glide('#indexFeedbackCarousel', {
        type: 'slider',
        perView: 1,
    }).mount();
}

//----------------------------------------------------------------------------------------------------------
const productImagesCarouselList = document.querySelectorAll('.product-carousel');

if (productImagesCarouselList.length > 0) {
    productImagesCarouselList.forEach(slider => {
        new Glide(slider, {
            type: 'slider',
            perView: 1,
        }).mount();
    })

}

//----------------------------------------------------------------------------------------------------------
const productPhotosCarouselList = document.querySelectorAll('.product-photos-carousel');

if (productPhotosCarouselList.length > 0) {
    productPhotosCarouselList.forEach(slider => {
        new Glide(slider, {
            type: 'slider',
            gap: 0,
			bound: true,
            perView: 3,
            breakpoints: {
                567: {
                    perView: 1,
                },
                768: {
                    perView: 2,
                },
                1440: {
                    perView: 3,
                },
            },
        }).mount();
    })

}

//----------------------------------------------------------------------------------------------------------
const productsOtherCarouselList = document.querySelectorAll('.product-other-carousel');

if (productsOtherCarouselList.length > 0) {
    productsOtherCarouselList.forEach(slider => {
        new Glide(slider, {
            type: 'slider',
            gap: 0,
			bound: true,
            perView: 4,
            breakpoints: {
                740: {
                    perView: 1,
                },
                1040: {
                    perView: 2,
                },
                1440: {
                    perView: 3,
                },
            },
        }).mount();
    })

}
