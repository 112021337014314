// const newBankCardButton = document.getElementById('addNewBankCard');

// newBankCardButton?.addEventListener('click', () => {
//
// });

const cardInputs = document.querySelectorAll('input[data-user-bank-card]');
const cardDeleteButtons = document.querySelectorAll('[data-delete-bank-card]');
const cardsCheckbox = document.querySelector('#bankCardsCheckbox');
const cashCheckbox = document.querySelector('#cashCheckbox');

cardInputs.forEach(input => {
    input.addEventListener('click', async e => {
        e.preventDefault();
        e.stopPropagation();
        try {
            await window.axios.post('/profile/payment-methods/' + input.dataset.userBankCard, {
                '_method': 'PUT',
                is_default: !!e.target.checked,
            })
            cardInputs.forEach(i => {
                if (input.isSameNode(i)) {
                    i.checked = !e.target.checked;
                } else {
                    i.checked = false;
                }
            });
            cardsCheckbox.checked = e.target.checked;
            cashCheckbox.checked = !e.target.checked;
        } catch (e) {
            console.error(e);
        }
    })
});

cardDeleteButtons.forEach(el => {
    el.addEventListener('click', async e => {
        e.preventDefault();
        e.stopPropagation();
        if (!confirm('Вы точно хотите удалить привязанную карту?')) {
            return;
        }
        try {
            await window.axios.post('/profile/payment-methods/' + el.dataset.deleteBankCard, {
                '_method': 'DELETE',
            })
            el.closest('[data-card-container]').remove();
        } catch (e) {
            console.error(e);
        }
    })
});

cardsCheckbox?.addEventListener('click', e => {
    e.preventDefault();
    e.stopPropagation();
});

cashCheckbox?.addEventListener('click', async e => {
    e.preventDefault();
    e.stopPropagation();
    try {
        await window.axios.post('/profile/payment-methods/cash')
        cardInputs.forEach(i => {
            i.checked = false;
        });
        cardsCheckbox.checked = false;
        cashCheckbox.checked = true;
    } catch (e) {
        console.error(e);
    }
});
