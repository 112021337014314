window.onload = () => bodyLoaded();

function bodyLoaded() {
    const dataStorageAcceptCookie = sessionStorage.getItem("accept-cookie")
    const sectionAcceptCookie = document.body.querySelector('#sectionAcceptCookie')
    const buttonAcceptCookie = document.body.querySelector('#buttonAcceptCookie')

    if (dataStorageAcceptCookie !== '1') {
        sectionAcceptCookie?.classList.add('-show')
        buttonAcceptCookie?.addEventListener('click', acceptCookie)
    } else {
        sectionAcceptCookie?.classList.remove('-show')
    }

    document.querySelectorAll('a[href^="#"]')?.forEach(link => {
        link.addEventListener('click', function(e) {
            e.preventDefault()
            let href = this.getAttribute('href').substring(1)
            const scrollTarget = document.getElementById(href)
            const topOffset = document.querySelector('.app-header').offsetHeight
            // const topOffset = 0; // если не нужен отступ сверху
            const elementPosition = scrollTarget.getBoundingClientRect().top
            const offsetPosition = elementPosition - topOffset

            window.scrollBy({
                top: offsetPosition,
                behavior: 'smooth'
            });
        });
    })
	document.querySelector('#buttonToUp')?.addEventListener('click', () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	});
    document.body.querySelector('#buttonOpenAsideFilters')?.addEventListener('click', () => openAside('asideFilters'))
    document.body.querySelector('#buttonCloseAsideFilters')?.addEventListener('click', () => closeAside('asideFilters'))

    document.body.querySelector('#buttonOpenAsideProfile')?.addEventListener('click', () => openAside('asideProfile'))
    document.body.querySelector('#closeAsideProfile')?.addEventListener('click', () => closeAside('asideProfile'))

    document.body.querySelector('#buttonOpenAsideMenuMobile')?.addEventListener('click', openAsideMenu)
    document.body.querySelector('#buttonOpenAsideMenuTablet')?.addEventListener('click', openNavigationTablet)
    document.body.querySelector('#buttonCloseAsideMenu')?.addEventListener('click', closeAsideMenu)
    document.body.querySelector('#buttonCloseCatalog')?.addEventListener('click', closeCatalog)

    const buttonCatalogDesktop = document.body.querySelector('#buttonCatalogDesktop')
    buttonCatalogDesktop?.addEventListener('click', openCatalogDesktop)
    buttonCatalogDesktop?.addEventListener('mouseenter', openCatalogDesktop)
    buttonCatalogDesktop?.addEventListener('mouseleave', catalogButtonMouseLeave)

    const buttonCatalogMobile = document.body.querySelector('#buttonCatalogMobile')
    buttonCatalogMobile?.addEventListener('click', openCatalog)

    const buttonOpenCatalog = document.body.querySelector('#buttonOpenCatalog')
    buttonOpenCatalog?.addEventListener('click', openOnlyCatalog)

    const indexPageButtonOpenCatalog = document.body.querySelector('#indexPageButtonOpenCatalog')
    indexPageButtonOpenCatalog?.addEventListener('click', openOnlyCatalog)

    const inputSearch =  document.body.querySelector('#inputSearch')

    inputSearch?.addEventListener('focus', focusSearch)
    inputSearch?.addEventListener('blur', blurSearch)

    window.addEventListener('scroll', handleScroll)
    //window.addEventListener('resize', closeAll)
    const headerHeight = document.body.querySelector('.app-header')?.clientHeight ?? 0;
    const breadcrumbsHeight = document.body.querySelector('.app-breadcrumbs')?.clientHeight ?? 0;
    document.body.style.setProperty('--header-height', (`${headerHeight + breadcrumbsHeight}px`))

}

function acceptCookie() {
    sessionStorage.setItem("accept-cookie", "1")
    document.body.querySelector('#sectionAcceptCookie')?.classList.remove('-show')
}

function focusSearch() {
    const headerUserWidth = document.body.querySelector('#headerUser').clientWidth || 0
    const search = document.body.querySelector('#inputSearchWrap')
    search?.style.setProperty('--margin-width', (`${headerUserWidth}px`))
    search?.classList.add('-active')
}
function blurSearch() {
    document.body.querySelector('#inputSearchWrap')?.classList.remove('-active')
}

function handleScroll() {
    hideTextHeader()
	showButtonToUp()
}

function hideTextHeader() {
    const position = window.scrollY
    if (position > 100) {
        document.body.querySelector('.header-top')?.classList.add('-hide-text-mobile')
    } else {
        document.body.querySelector('.header-top')?.classList.remove('-hide-text-mobile')
    }
}

function showButtonToUp() {
	const position = window.scrollY
	const screenHeight = screen.height
	if (position > screenHeight * 2) {
		document.body.querySelector('#buttonToUp')?.classList.add('-show')
	} else {
		document.body.querySelector('#buttonToUp')?.classList.remove('-show')
	}
}

function getHeaderHeight() {
    const headerHeight = document.body.querySelector('.header-top').clientHeight || 0;
    document.body.style.setProperty('--header-height', (`${headerHeight}px`))
}

function openAside(idAside) {
    const elementAside = document.body.querySelector(`#${idAside}`)
    getHeaderHeight()
    closeAsideMenu()
    document.body.classList.add('--hidden');
    elementAside.classList.add('-show');
    setTimeout(() => {
        elementAside.classList.add('-open');
    }, 10)
}
function closeAside(idAside) {
    let elementAside = null
    if (idAside) {
        elementAside = document.body.querySelector(`#${idAside}`)
    } else {
        elementAside = document.body.querySelector('.aside.-open.-show')
    }
    if (elementAside) {
        elementAside.classList.remove('-open');
        setTimeout(() => {
            elementAside.classList.remove('-show');
        }, 250)
    }
    document.body.classList.remove('--hidden');

}

function isCursorOnElem( event, elem ){
    const mouseX = event.clientX
    const mouseY = event.clientY
    const rect = elem.getBoundingClientRect()

    return (
        mouseX > rect.x && mouseX < rect.right
        &&
        mouseY > rect.y && mouseY < rect.bottom
    )
}

function openOnlyCatalog() {
    openAsideMenu()
    openCatalog()
    document.body.querySelector('#buttonCloseCatalog').style.display = 'none'
}

function openCatalogDesktop() {
    closeAsideMenu()
    openCatalog()
}

function openCatalog() {
	const catalog = document.body.querySelector('#catalog')
	if (catalog) {
		catalog.classList.add('-show')
		catalog.querySelector('.container')?.addEventListener('click', closeCatalog)
	}
    document.body.querySelector('#buttonCatalogMenu')?.classList.add('-active')
}

function trackCursorOnCatalog(event) {
    const catalogContent = document.body.querySelector('.app-catalog')
    if (catalogContent && !isCursorOnElem(event, catalogContent)) {
        closeCatalog()
    }
}

function closeCatalog() {
    const catalog = document.body.querySelector('#catalog')
    if (catalog) {
        catalog.classList.remove('-show')

        document.body.querySelector('.button-accent.-active')?.classList?.remove('-active')
        document.body.removeEventListener('mousemove', trackCursorOnCatalog)
        setTimeout(() => {
            document.body.querySelector('#buttonCloseCatalog').style.display = 'inline-flex'
        }, 200)
    }
}
function catalogButtonMouseLeave(event) {
    setTimeout(() => {
        document.body.addEventListener( 'mousemove', trackCursorOnCatalog)
    }, 200)
}

function openAsideMenu() {
    getHeaderHeight()
    closeAside()

    const elementNavigationWrap = document.body.querySelector('#asideMenuWrap');
    const elementNavigationContent = document.body.querySelector('#asideMenu');
    document.body.classList.add('--hidden');
    elementNavigationWrap.classList.add('-show');
    elementNavigationContent.classList.add('-show');
    setTimeout(() => {
        elementNavigationWrap.classList.add('-open');
        elementNavigationContent.classList.add('-move');
    }, 20)
}

function closeAsideMenu() {
    closeCatalog()
    const elementNavigationWrap = document.body.querySelector('#asideMenuWrap');
    const elementNavigationContent = document.body.querySelector('#asideMenu');

    if (elementNavigationWrap && elementNavigationContent) {
        elementNavigationWrap.classList.remove('-open');
        elementNavigationContent.classList.remove('-move');
        document.body.classList.remove('--hidden');
        setTimeout(() => {
            elementNavigationWrap.classList.remove('-show');
            elementNavigationContent.classList.remove('-show');
        }, 500)
    }
}

function openNavigationTablet() {
    closeCatalog()
    openAsideMenu()
}

/* Функция предназначена для склонения слов используемых вместе с числительными
* @param number (Number) - число
* @param words (Array) - 3 слова соответствующие числительным (1, 2, 10)
* @return string - 1 слово, соответствующее числу
*/
function transChoose (number, words) {
    const cases = [2, 0, 1, 1, 1, 2];
    return words[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
}

function changeInputNumber(event) {
    const unit = event.target.nextElementSibling;
    unit.textContent = transChoose(Number(event.target.value), ['человек', 'человека', 'человек']);
}

function closeAll() {
    closeAsideMenu()
    window.closeModal()
    closeAside()
}

export { closeCatalog, closeAsideMenu, closeAside }
