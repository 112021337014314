const shareAllowed = !!navigator.share;
const clipboardAllowed = !!navigator.clipboard;

window.handleShareClick = async btn => {
	const shareUrl = btn.dataset.shareUrl;
	let textContainer = btn.querySelector('[data-text]');
	if (textContainer === null) {
		textContainer = btn;
	}
	const textContainerInitialText = textContainer.textContent;

	if (shareAllowed) {
		await navigator.share({url: shareUrl})
	} else if (clipboardAllowed) {
		try {
			await navigator.clipboard.writeText(shareUrl);
			textContainer.innerText = 'Ссылка скопирована!';
			setTimeout(() => {
				textContainer.innerText = textContainerInitialText;
			}, 2000);
		} catch (e) {
			console.error('copy failed', e);
		}
	}
};
