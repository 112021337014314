import './bootstrap';
import './livewire.js';
import './swipe.js';
import './range-slider.js';
import './carousel.js';
import './viewer.js';
import './modal.js';
import './auth.js';
import './script.js';
import './login.js';
import './bank-cards.js';
import './favorites.js';
import './bought-products.js';
import './callback.js';
import './feedback.js';
import './share.js';
import './profile.js';
import './products.js';
//import './snow.js';

import Alpine from 'alpinejs';
import Precognition from 'laravel-precognition-alpine';

window.Alpine = Alpine;

Alpine.plugin(Precognition);
Alpine.start();
