
const rangeSlidersList = document.body.querySelectorAll("[range-slider]")

if (rangeSlidersList.length > 0) {
    rangeSlidersList.forEach(range => {
        initRange(range)
    })
}

function initRange(range) {
    let sliderOne = range.querySelector("[slider-1]");
    let sliderTwo = range.querySelector("[slider-2]");
    slideOne(range)
    slideTwo(range)
    sliderOne.addEventListener('input', () => slideOne(range))
    sliderTwo.addEventListener('input', () => slideTwo(range))
}

function slideOne(range) {
    let sliderOne = range.querySelector("[slider-1]");
    let sliderTwo = range.querySelector("[slider-2]");
    let displayValue = range.querySelector("[range-value]");
    let minGap = 0;

    if (parseInt(sliderTwo.value) - parseInt(sliderOne.value) <= minGap) {
        sliderOne.value = parseInt(sliderTwo.value) - minGap;
    }
    displayValue.textContent = "от " + sliderOne.value.toLocaleString() + " до " + sliderTwo.value.toLocaleString();
    fillColor(range);
}
function slideTwo(range) {
    let sliderOne = range.querySelector("[slider-1]");
    let sliderTwo = range.querySelector("[slider-2]");
    let displayValue = range.querySelector("[range-value]");
    let minGap = 0;

    if (parseInt(sliderTwo.value) - parseInt(sliderOne.value) <= minGap) {
        sliderTwo.value = parseInt(sliderOne.value) + minGap;
    }
    displayValue.textContent = "от " + sliderOne.value.toLocaleString() + " до " + sliderTwo.value.toLocaleString();
    fillColor(range);
}
function fillColor(range) {
    let sliderOne = range.querySelector("[slider-1]");
    let sliderTwo = range.querySelector("[slider-2]");
    let sliderTrack = range.querySelector("[slider-track]");
    let sliderMaxValue = sliderOne.max;
    let sliderMinValue = sliderOne.min;

    const percent1 = (sliderOne.value - sliderMinValue) / (sliderMaxValue - sliderMinValue) * 100;
    const percent2 = (sliderTwo.value - sliderMinValue) / (sliderMaxValue - sliderMinValue) * 100;
    sliderTrack.style.backgroundImage = `linear-gradient(to right, #DDABA1 ${percent1}% , #E63E1D ${percent1}% ,#E63E1D ${percent2}%, #DDABA1 ${percent2}%)`;
}

