import Validation from "./util/validation.js";

const feedbackForm = document.querySelector('#formFeedback');

if(feedbackForm) {
    const imageInputPlaceholder = feedbackForm.querySelector('[name="imageInputPlaceholder"]');
    const imageInput = feedbackForm.querySelector('[name="image"]');
    const successMessage = feedbackForm.querySelector('#feedbackFormSuccessMessage');

    feedbackForm.addEventListener('submit', async e => {
        e.preventDefault();
        e.stopPropagation();
        const validation = new Validation(feedbackForm);
        const data = new FormData(feedbackForm);
        try {
            validation.clearErrors();
            const res = await axios.post('/feedback', data);
            successMessage.classList.add('-show');
            feedbackForm.reset();
        } catch (e) {
            if (e?.response?.status === 422) {
                validation.addErrors(e.response.data.errors);
            }
        }
    });

    imageInputPlaceholder.addEventListener('click', e => {
        imageInput.click();
    });
    imageInput.addEventListener('change', e => {
        imageInputPlaceholder.setAttribute('placeholder', e.target.files[0].name);
    });
}
