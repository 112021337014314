const favoriteProductsMoreButton = document.querySelector('#favoriteProductsMore');
const favoriteProductsContainer = document.querySelector('#favoriteProductsContainer');

if (favoriteProductsMoreButton && favoriteProductsContainer) {
    window.favoriteProductsPage = 1;
    favoriteProductsMoreButton.addEventListener('click', async e => {
        const res = await axios.get('/profile/favorites?page=' + (++window.favoriteProductsPage));
        if (res.data !== '') {
            const div = document.createElement('div');
            div.innerHTML = res.data;
            initFavoriteToggles(div.querySelectorAll('[data-product]'));
            favoriteProductsContainer.append(...div.children);
            // favoriteProductsContainer.innerHTML = favoriteProductsContainer.innerHTML + res.data;
        } else {
            favoriteProductsMoreButton.classList.add('d-none');
        }
    });
}
