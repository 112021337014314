const boughtProductsMoreButton = document.querySelector('#boughtProductsMore');
const boughtProductsContainer = document.querySelector('#boughtProductsContainer');

if (boughtProductsMoreButton && boughtProductsContainer) {
    window.boughtProductsPage = 1;
    boughtProductsMoreButton.addEventListener('click', async e => {
        const res = await axios.get('/profile/bought-products?page=' + (++window.boughtProductsPage));
        if (res.data !== '') {
            const div = document.createElement('div');
            div.innerHTML = res.data;
            boughtProductsContainer.append(...div.children);
        } else {
            boughtProductsMoreButton.classList.add('d-none');
        }
    });
}
