import { closeCatalog, closeAsideMenu, closeAside } from './script.js'

// Вешаем на прикосновение функцию handleTouchStart
document.addEventListener('touchstart', handleTouchStart, false);
// А на движение пальцем по экрану - handleTouchMove
document.addEventListener('touchmove', handleTouchMove, false);

let xDown = null;
let yDown = null;

function handleTouchStart(evt) {
    xDown = evt.touches[0].clientX;
    yDown = evt.touches[0].clientY;
}

function handleTouchMove(evt) {
    if ( ! xDown || ! yDown ) {
        return;
    }

    let xUp = evt.touches[0].clientX;
    let yUp = evt.touches[0].clientY;

    let xDiff = xDown - xUp;
    let yDiff = yDown - yUp;
    // немного поясню здесь. Тут берутся модули движения по оси абсцисс и ординат (почему модули? потому что если движение сделано влево или вниз, то его показатель будет отрицательным) и сравнивается, чего было больше: движения по абсциссам или ординатам. Нужно это для того, чтобы, если пользователь провел вправо, но немного наискосок вниз, сработал именно коллбэк для движения вправо, а ни как-то иначе.
    if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
        if ( xDiff > 0 ) {
            /* left swipe */
            const isProfileOpen =  document.body.querySelector('#asideProfile')?.classList.contains('-show')
			//todo: если двигать range-slider, то панель фильтров закрывается
			//const isFiltersOpen =  document.body.querySelector('#asideFilters')?.classList.contains('-show')
            if (isProfileOpen) {
                closeAside('asideProfile')
            }
            //if (isFiltersOpen) {
            //    closeAside('asideFilters')
            //}
        } else {
            /* right swipe */
            const isCatalogOpen =  document.body.querySelector('#catalog')?.classList.contains('-show')
            const isMenuOpen =  document.body.querySelector('#asideMenu')?.classList.contains('-show')
            if (isCatalogOpen) {
                closeCatalog()
            } else if (isMenuOpen) {
                closeAsideMenu()
            }
        }
    } else { // Это вам, в общем-то, не надо, вы ведь только влево-вправо собираетесь двигать
        if ( yDiff > 0 ) {
            /* up swipe */
        } else {
            /* down swipe */
        }
    }
    /* reset values */
    xDown = null;
    yDown = null;
}
