import Validation from './util/validation.js';

window.sendCallback = async formEl => {
    const validation = new Validation(formEl);
    const data = new FormData(formEl);
    try {
        validation.clearErrors();
        await axios.post('/callback', data);
		return true;
    } catch (e) {
        if (e?.response?.status === 422) {
            validation.addErrors(e.response.data.errors);
        }
		return false;
    }
}
