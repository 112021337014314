class Validation {
    #formEl;

    constructor(formEl) {
        this.#formEl = formEl;
    }

    clearErrors() {
        this.#formEl.querySelectorAll('.message-error').forEach(el => el.remove());
        this.#formEl.querySelectorAll('.-error').forEach(el => el.classList.remove('-error'));
    }

    addErrors(errors) {
        for (const name in errors) {
            const input = this.#formEl.querySelector(`[name="${name}"]`);
            const messages = errors[name].join(', ');
            if (input) {
                const message = document.createElement('div');
                message.classList.add('message-error');
                message.innerText = messages;
                input.parentNode.classList.add('-error');
                input.parentNode.lastChild.after(message);
            }
        }
    }
}

export default Validation;
