document.addEventListener('livewire:init', () => {
	Livewire.directive('confirm', ({ el, directive, component, cleanup }) => {
		let content =  directive.expression

		// The "directive" object gives you access to the parsed directive.
		// For example, here are its values for: wire:click.prevent="deletePost(1)"
		//
		// directive.raw = wire:click.prevent
		// directive.value = "click"
		// directive.modifiers = ['prevent']
		// directive.expression = "deletePost(1)"

		let onClick = e => {
			if (! confirm(content)) {
				e.preventDefault()
				e.stopImmediatePropagation()
			}
		}

		el.addEventListener('click', onClick, { capture: true })

		// Register any cleanup code inside `cleanup()` in the case
		// where a Livewire component is removed from the DOM while
		// the page is still active.
		cleanup(() => {
			el.removeEventListener('click', onClick)
		})
	})
});
