import Viewer from 'viewerjs';

const imgGallery = document.querySelector('.viewer-images')

if (imgGallery) {
    new Viewer(imgGallery, {
        title: false,
        toolbar: false,
    });
}

const indexCertificatesViewer = document.querySelector('#indexCertificatesViewer');

if (indexCertificatesViewer) {
    new Viewer(indexCertificatesViewer, {
        title: false,
        toolbar: {
            prev: 1,
            next: 1,
        },
        navbar: false
    });
}

const productPhotosViewer = document.querySelector('#productPhotosViewer');

if (productPhotosViewer) {
    new Viewer(productPhotosViewer, {
        title: false,
        toolbar: {
            prev: 1,
            next: 1,
        },
        navbar: false
    });
}

const productImagesViewerList = document.querySelectorAll('.product-images-viewer');

if (productImagesViewerList.length > 0) {
    productImagesViewerList.forEach(viewer => {
        new Viewer(viewer, {
            title: false,
            toolbar: {
				prev: 1,
				next: 1,
			},
            navbar: false
        });
    })

}
